type NotificationSettings = {
  message: string,
  timeout?: number,
  color?: ColorAlias,
  isCloseable?: boolean
}

export const useNotification = () => {
  const DEFAULTS: NotificationSettings = {
    isCloseable: true,
    message: '',
    timeout: 3000,
    color: 'primary'
  }

  const isShown = useState('notification-is-shown', () => false)
  const settings = useState('notification-settings', () => DEFAULTS)

  const notify = async (newSettings: NotificationSettings) => {
    if (isShown.value) {
      await hide()
      await nextTick()
    }

    await nextTick(() => {
      isShown.value = true
      settings.value = {
        ...DEFAULTS,
        ...newSettings
      }
    })
  }

  const hide = async () => {
    isShown.value = false
    settings.value = DEFAULTS
  }

  const notifySuccess = (newSettings: NotificationSettings) =>
    notify({
      ...newSettings,
      color: 'success'
    })
  const notifyInfo = (newSettings: NotificationSettings) =>
    notify({
      ...newSettings,
      color: 'info'
    })
  const notifyWarning = (newSettings: NotificationSettings) =>
    notify({
      ...newSettings,
      color: 'warning'
    })
  const notifyError = (newSettings: NotificationSettings) =>
    notify({
      ...newSettings,
      color: 'error'
    })

  return {
    isShown,
    settings,
    notify,
    hide,
    notifySuccess,
    notifyInfo,
    notifyWarning,
    notifyError
  }
}
