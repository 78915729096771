import { type CookieRef, type CookieOptions as NuxtCookieOptions } from "#app"

type CookieOptions = Pick<NuxtCookieOptions, 'sameSite' | 'path' | 'maxAge'>

export const useCookieService = <T = string>(name: string, options: CookieOptions = {}): CookieRef<T> => {
  const {
    public: {
      cookiePrefix: prefix,
      cookieMaxAge: defaultMaxAge
    }
  } = useRuntimeConfig()

  const cookieName = `${prefix}/${name}`
  const {
    sameSite = 'lax',
    path = '/',
    maxAge = defaultMaxAge
  } = options

  return useCookie<T>(cookieName, {
    sameSite,
    path,
    maxAge
  })
}
