export const useAuthTokens = () => {
  const cookie = useCookieService<Tokens | null>('authTokens', {
    maxAge: 31536000  // 1 year
  })
  const tokens = useState('auth-tokens', () => cookie.value)

  watch(
    tokens,
    () => cookie.value = tokens.value,
    { deep: true }
  )

  const hasTokens = computed(() => !!tokens.value)

  const updateTokens = (newTokens: Tokens) => {
    tokens.value = newTokens
  }
  const invalidateTokens = () => {
    tokens.value = null
  }

  const isAboutToExpire = () => {
    if (!hasTokens.value) {
      return false
    }

    const now = new Date().getTime()
    const expires = tokens.value?.expiresIn ?? now
    const difference = expires - now

    return difference <= 10000  // 10 seconds
  }

  return {
    tokens,
    hasTokens,
    updateTokens,
    invalidateTokens,
    isAboutToExpire
  }
}

export const useOAuthProxy = () => {
  const {
    $caseChangingFetch,
    getTokens
  } = useAPIUtils()
  const {
    tokens,
    hasTokens,
    updateTokens,
    invalidateTokens
  } = useAuthTokens()

  const refreshPromise = ref<Promise<AuthResponse> | null>(null)

  const doNothing = () => {}

  const authenticateSession = async (credentials: Credentials): Promise<void> =>
    $caseChangingFetch('/api/oauth/token/', {
      method: 'POST',
      body: {
        ...credentials,
        grantType: 'password'
      }
    })
      // @ts-ignore
      .then(getTokens)
      .then(updateTokens)

  const refreshSession = async (refreshToken: string): Promise<void> => {
    if (!refreshPromise.value) {
      refreshPromise.value = $caseChangingFetch('/api/oauth/token/', {
        method: 'POST',
        body: {
          refreshToken,
          grantType: 'refresh_token'
        }
      })
    }

    return refreshPromise.value
      .then(getTokens)
      .then(updateTokens)
      .finally(() => {
        refreshPromise.value = null
      })
  }

  const invalidateSession = async (): Promise<void> => {
    if (hasTokens.value) {
      await $caseChangingFetch('/api/oauth/revoke_token/', {
        method: 'POST',
        body: {
          token: tokens.value!.accessToken,
          tokenTypeHint: 'access_token'
        }
      })
        .catch(doNothing)
    }

    invalidateTokens()
  }

  return {
    authenticateSession,
    refreshSession,
    invalidateSession
  }
}
