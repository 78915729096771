export const useValidators = () => {
  const { $R, $dayjs } = useNuxtApp()

  const isBoolean = $R.is(Boolean)
  const isNumber = $R.is(Number)
  const isString = $R.is(String)
  const isArray = $R.is(Array)
  const isFunction = $R.is(Function)

  // R.is(Object, []) => true.
  // Let's explicitly check that the argument ISN'T an array.
  const isObject = $R.allPass([
    $R.is(Object),
    $R.complement(isArray)
  ])

  const isFormData = $R.tryCatch(
    $R.pipe($R.toString, $R.equals('[object FormData]')),
    $R.F
  )

  const isTimeflake = $R.allPass([
    isString,
    $R.test(/^[A-Za-z0-9]{22}$/)
  ])

  const _isDayjs = (format: string) => (v: string) => $dayjs(v, format, true).isValid()
  const isDate = _isDayjs('YYYY-MM-DD')
  const isDatetime = _isDayjs('YYYY-MM-DDTHH:mm[:ss]')

  const isNotNil = $R.complement($R.isNil)
  const isNotEmpty = $R.complement($R.isEmpty)

  const isIn = (values: any[]) => (val: any) => $R.includes(val, values)

  const isInChoices = (choices: [any, string][] | any[]) => (val: any) => $R.pipe(
    $R.map(
      $R.when(isArray, $R.head)
    ),
    values => isIn(values)(val)
  )(choices)

  return {
    isBoolean,
    isNumber,
    isString,
    isArray,
    isFunction,
    isObject,
    isFormData,
    isTimeflake,
    isNotNil,
    isNotEmpty,
    isIn,
    isInChoices,
    isDate,
    isDatetime
  }
}
