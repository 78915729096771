type Caseable = object | string[] | string
type CaseFunc = (input: string) => string

export const useCasing = () => {
  const { $R, $case } = useNuxtApp()
  const { isArray, isFormData, isObject } = useValidators()

  const _changeKeysToCase = (caseFunc: CaseFunc) => (data: Caseable): Caseable => $R.cond([
    [isArray, $R.map(_changeKeysToCase(caseFunc))],
    [isFormData, (formData: object) => {
      const newFormData = new FormData()
      // @ts-ignore
      for (const [key, value] of formData.entries()) {
        newFormData.append(caseFunc(key), value)
      }
      return newFormData
    }],
    [isObject, $R.pipe(
      $R.keys,
      $R.reduce(
        (result: object, key: any) => $R.pipe(
          $R.prop(key),
          $R.when($R.anyPass([isArray, isFormData, isObject]), _changeKeysToCase(caseFunc)),
          $R.assoc(caseFunc(key), $R.__, result)
        )(data as any),
        {}
      )
    )],
    [$R.T, $R.identity]
  ])(data as any)

  const keysToSnakeCase = _changeKeysToCase($case.snake)
  const valuesToSnakeCase = $R.map($case.snake)
  const keysToCamelCase = _changeKeysToCase($case.camel)
  const valuesToCamelCase = $R.map($case.camel)

  return {
    keysToSnakeCase,
    keysToCamelCase,
    valuesToSnakeCase,
    valuesToCamelCase
  }
}
