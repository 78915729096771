type Theme = 'light' | 'dark'

export const useThemeToggle = () => {
  const cookie = useCookieService<Theme>('theme')
  const state = useState<Theme>('theme', () => cookie.value ?? 'light')

  const theme = computed({
    get: () => state.value,
    set: (newValue: Theme) => {
      cookie.value = newValue
      state.value = newValue
    }
  })

  const isLight = computed(() => theme.value === 'light')
  const isDark = computed(() => theme.value === 'dark')

  const toggle = () => {
    theme.value = isLight.value
      ? 'dark'
      : 'light'
  }

  return {
    theme,
    isLight,
    isDark,
    toggle
  }
}
