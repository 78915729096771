export default defineNuxtRouteMiddleware(async to => {
  const { hasTokens } = useAuthTokens()

  const isAuthRequired = !to.meta.isPublic
  const isAuthenticated = hasTokens.value

  let name

  if (isAuthRequired && !isAuthenticated) {
    name = 'login'
  } else if (!isAuthRequired && isAuthenticated) {
    name = 'index'
  }

  if (name) {
    return navigateTo({ name })
  }
})
