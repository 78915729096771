export const useSingaAPI = () => {
  const {
    public: { singaApiUrl, proxyApiUrl }
  } = useRuntimeConfig()
  const oAuthProxy = useOAuthProxy()
  const { createAPIOperations } = useAPIUtils()

  const proxiedPublicAPI = useAPIClient({ baseURL: `${proxyApiUrl}/v1.4/` })

  const privateAPI = useAPIClient({ baseURL: `${singaApiUrl}/private/v1/` })
  const proxiedPrivateAPI = useAPIClient({ baseURL: `${proxyApiUrl}/private/v1/` })

  return {
    Authentication: {
      authenticate: (credentials: Credentials) =>
        oAuthProxy.authenticateSession(credentials),
      refresh: (refreshToken: string) =>
        oAuthProxy.refreshSession(refreshToken),
      invalidate: () =>
        oAuthProxy.invalidateSession()
    },

    Me: {
      retrieve: (): Promise<User> => proxiedPublicAPI.get('/me/')
    },

    Content: {
      Artists: createAPIOperations<Artist>(
        proxiedPrivateAPI,
        '/content/artists/',
        ['list', 'retrieve', 'create']
      ),
      Genres: createAPIOperations<Genre>(
        proxiedPrivateAPI,
        '/content/genres/',
        ['list', 'retrieve']
      ),
      Catalogs: createAPIOperations<Catalog>(
        proxiedPrivateAPI,
        '/content/catalogs/',
        ['list', 'retrieve']
      ),
      Sources: createAPIOperations<ContentSource>(
        proxiedPrivateAPI,
        '/content/sources/',
        ['list', 'create']
      ),
      Songs: {
        ...createAPIOperations<Song>(
          proxiedPrivateAPI,
          '/content/songs/',
          ['list', 'retrieve', 'create']
        ),

        Languages: createAPIOperations<SongLanguage>(
          proxiedPrivateAPI,
          '/content/songs/languages/',
          ['list']
        )
      },
      SongVariants: createAPIOperations<SongVariant>(
        proxiedPrivateAPI,
        '/content/songs/variants/',
        ['list', 'retrieve']
      ),

      Media: {
        SourceFiles: createAPIOperations<SourceFile>(
          privateAPI,
          '/content/media/files/sources/',
          ['create']
        ),

        MediaFiles: {
          ...createAPIOperations<MediaFile>(
            proxiedPrivateAPI,
            '/content/media/media-files/sources/',
            ['list', 'create', 'partialUpdate', 'destroy']
          ),

          Definitions: {
            ...createAPIOperations<MediaFileDefinition>(
              proxiedPrivateAPI,
              '/content/media/media-files/definitions/',
              ['list', 'partialUpdate']
            ),

            Groups: createAPIOperations<MediaFileGroup>(
              proxiedPrivateAPI,
              '/content/media/media-files/definitions/groups/',
              ['list', 'create', 'retrieve', 'update', 'destroy', 'setActive']
            )
          },

          Settings: {
            Groups: {
              Process: createAPIOperations<AudioProcessingSettingGroup>(
                proxiedPrivateAPI,
                '/content/media/media-files/settings/groups/process/',
                ['list', 'create', 'toggleActive']
              )
            },
            Analyze: createAPIOperations<AudioAnalyzeSetting>(
              proxiedPrivateAPI,
              '/content/media/media-files/settings/analyze/',
              ['list', 'create', 'setActive']
            ),
            Process: createAPIOperations<AudioProcessingSetting>(
              proxiedPrivateAPI,
              '/content/media/media-files/settings/process/',
              ['list', 'create', 'setActive']
            ),
            Preview: createAPIOperations<AudioPreviewSetting>(
              proxiedPrivateAPI,
              '/content/media/media-files/settings/preview/',
              ['list', 'create', 'setActive']
            )
          },

          Results: {
            AnalyzedAudio: createAPIOperations<AnalyzedAudio>(
              proxiedPrivateAPI,
              '/content/media/media-files/results/analyzed-audio/',
              ['list']
            )
          }
        },

        Pipelines: {
          ...createAPIOperations<Pipeline>(
            proxiedPrivateAPI,
            '/content/media/pipelines/',
            ['list', 'create', 'retrieve', 'update']
          ),

          Steps: createAPIOperations<PipelineStep>(
            proxiedPrivateAPI,
            '/content/media/pipelines/steps/',
            ['list', 'retrieve', 'process']
          ),
          Jobs: createAPIOperations<PipelineJob>(
            proxiedPrivateAPI,
            '/content/media/pipelines/jobs/',
            ['list']
          )
        }
      }
    }
  }
}
