import { FetchError, type FetchOptions } from 'ofetch'
import { useAPIUtils } from './utils'

export const useCustomFetch = (config: Partial<FetchOptions>) => {
  const {
    $caseChangingFetch,
    isUnauthorized
  } = useAPIUtils()
  const {
    tokens,
    hasTokens
  } = useAuthTokens()
  const {
    refreshSession,
    invalidateSession
  } = useOAuthProxy()
  const { notifyError } = useNotification()

  const doFetch = (method: string) => async (url: string, options?: Partial<APIOptions>): Promise<any> => {
    const request = {
      method,
      retry: 0,
      headers: {},
      ...config,
      ...(options ?? {})
    }

    if (hasTokens.value) {
      const { accessToken } = tokens.value!

      request.headers = {
        ...request.headers,
        'Authorization': `Bearer ${accessToken}`
      }
    }

    try {
      return await $caseChangingFetch(url, request as any)
    } catch (err) {
      if (isUnauthorized(err as FetchError)) {
        if (options?.isRetry) {
          return goToLogin()
        } else {
          return refreshTokensAndTryAgain(method, url, options)
        }
      }

      throw err
    }
  }

  const goToLogin = async () => {
    await invalidateSession()
    await notifyError({ message: 'Session expired.' })

    return navigateTo({ name: 'login' }, {
      replace: true
    })
  }

  const refreshTokensAndTryAgain = async (method: string, url: string, options?: Partial<APIOptions>) => {
    const { refreshToken } = tokens.value!

    try {
      await refreshSession(refreshToken)
      return doFetch(method)(url, {
        ...(options ?? {}),
        isRetry: true
      })
    } catch (err) {
      return goToLogin()
    }
  }

  return doFetch
}

export const useAPIClient = (config?: Partial<FetchOptions>): APIClient => {
  const doFetch = useCustomFetch(config ?? {})

  return {
    get: doFetch('GET'),
    post: doFetch('POST'),
    put: doFetch('PUT'),
    patch: doFetch('PATCH'),
    delete: doFetch('DELETE')
  }
}
