import { default as _91artistId_936MAsQcPCsTMeta } from "/app/pages/content/artists/[artistId].vue?macro=true";
import { default as indexiSGkAizCKwMeta } from "/app/pages/content/artists/index.vue?macro=true";
import { default as _91catalogId_93hIuwuKIReOMeta } from "/app/pages/content/catalogs/[catalogId].vue?macro=true";
import { default as indexGKPOWNPNcWMeta } from "/app/pages/content/catalogs/index.vue?macro=true";
import { default as _91genreId_93ujAFMcN9MPMeta } from "/app/pages/content/genres/[genreId].vue?macro=true";
import { default as indexUtIBgodntVMeta } from "/app/pages/content/genres/index.vue?macro=true";
import { default as indexzLRcVsOIIPMeta } from "/app/pages/content/media/pipelines/[pipelineId]/index.vue?macro=true";
import { default as _91stepId_93OjYr8GfT44Meta } from "/app/pages/content/media/pipelines/[pipelineId]/steps/[stepId].vue?macro=true";
import { default as indexKfyfSeSLxbMeta } from "/app/pages/content/media/pipelines/index.vue?macro=true";
import { default as analyzersLYIrfG5mS8Meta } from "/app/pages/content/media/settings/analyzers.vue?macro=true";
import { default as previewsbCAMQQmGdoMeta } from "/app/pages/content/media/settings/previews.vue?macro=true";
import { default as groupsLXzgz0i1bBMeta } from "/app/pages/content/media/settings/processing/groups.vue?macro=true";
import { default as settingsZv4mNzLaFJMeta } from "/app/pages/content/media/settings/processing/settings.vue?macro=true";
import { default as processing2urGKkMx2IMeta } from "/app/pages/content/media/settings/processing.vue?macro=true";
import { default as settingszpmF6fIlywMeta } from "/app/pages/content/media/settings.vue?macro=true";
import { default as mediaDrCCvBMll4Meta } from "/app/pages/content/media.vue?macro=true";
import { default as _91songVariantId_93UxL2F1dKcjMeta } from "/app/pages/content/song-variants/[songVariantId].vue?macro=true";
import { default as indexgcqaSapqfEMeta } from "/app/pages/content/song-variants/index.vue?macro=true";
import { default as _91songId_93ufkhDCyErmMeta } from "/app/pages/content/songs/[songId].vue?macro=true";
import { default as batch_45createg5fWfvIoYWMeta } from "/app/pages/content/songs/batch-create.vue?macro=true";
import { default as indexFvg4mORv07Meta } from "/app/pages/content/songs/index.vue?macro=true";
import { default as contentc4uHeraAWtMeta } from "/app/pages/content.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
export default [
  {
    name: "content",
    path: "/content",
    meta: contentc4uHeraAWtMeta || {},
    component: () => import("/app/pages/content.vue").then(m => m.default || m),
    children: [
  {
    name: "content-artists-artistId",
    path: "artists/:artistId()",
    meta: _91artistId_936MAsQcPCsTMeta || {},
    component: () => import("/app/pages/content/artists/[artistId].vue").then(m => m.default || m)
  },
  {
    name: "content-artists",
    path: "artists",
    meta: indexiSGkAizCKwMeta || {},
    component: () => import("/app/pages/content/artists/index.vue").then(m => m.default || m)
  },
  {
    name: "content-catalogs-catalogId",
    path: "catalogs/:catalogId()",
    meta: _91catalogId_93hIuwuKIReOMeta || {},
    component: () => import("/app/pages/content/catalogs/[catalogId].vue").then(m => m.default || m)
  },
  {
    name: "content-catalogs",
    path: "catalogs",
    meta: indexGKPOWNPNcWMeta || {},
    component: () => import("/app/pages/content/catalogs/index.vue").then(m => m.default || m)
  },
  {
    name: "content-genres-genreId",
    path: "genres/:genreId()",
    meta: _91genreId_93ujAFMcN9MPMeta || {},
    component: () => import("/app/pages/content/genres/[genreId].vue").then(m => m.default || m)
  },
  {
    name: "content-genres",
    path: "genres",
    meta: indexUtIBgodntVMeta || {},
    component: () => import("/app/pages/content/genres/index.vue").then(m => m.default || m)
  },
  {
    name: "content-media",
    path: "media",
    meta: mediaDrCCvBMll4Meta || {},
    component: () => import("/app/pages/content/media.vue").then(m => m.default || m),
    children: [
  {
    name: "content-media-pipelines-pipelineId",
    path: "pipelines/:pipelineId()",
    meta: indexzLRcVsOIIPMeta || {},
    component: () => import("/app/pages/content/media/pipelines/[pipelineId]/index.vue").then(m => m.default || m)
  },
  {
    name: "content-media-pipelines-pipelineId-steps-stepId",
    path: "pipelines/:pipelineId()/steps/:stepId()",
    meta: _91stepId_93OjYr8GfT44Meta || {},
    component: () => import("/app/pages/content/media/pipelines/[pipelineId]/steps/[stepId].vue").then(m => m.default || m)
  },
  {
    name: "content-media-pipelines",
    path: "pipelines",
    meta: indexKfyfSeSLxbMeta || {},
    component: () => import("/app/pages/content/media/pipelines/index.vue").then(m => m.default || m)
  },
  {
    name: "content-media-settings",
    path: "settings",
    meta: settingszpmF6fIlywMeta || {},
    component: () => import("/app/pages/content/media/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "content-media-settings-analyzers",
    path: "analyzers",
    meta: analyzersLYIrfG5mS8Meta || {},
    component: () => import("/app/pages/content/media/settings/analyzers.vue").then(m => m.default || m)
  },
  {
    name: "content-media-settings-previews",
    path: "previews",
    meta: previewsbCAMQQmGdoMeta || {},
    component: () => import("/app/pages/content/media/settings/previews.vue").then(m => m.default || m)
  },
  {
    name: "content-media-settings-processing",
    path: "processing",
    meta: processing2urGKkMx2IMeta || {},
    redirect: processing2urGKkMx2IMeta?.redirect,
    component: () => import("/app/pages/content/media/settings/processing.vue").then(m => m.default || m),
    children: [
  {
    name: "content-media-settings-processing-groups",
    path: "groups",
    meta: groupsLXzgz0i1bBMeta || {},
    component: () => import("/app/pages/content/media/settings/processing/groups.vue").then(m => m.default || m)
  },
  {
    name: "content-media-settings-processing-settings",
    path: "settings",
    meta: settingsZv4mNzLaFJMeta || {},
    component: () => import("/app/pages/content/media/settings/processing/settings.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: "content-song-variants-songVariantId",
    path: "song-variants/:songVariantId()",
    meta: _91songVariantId_93UxL2F1dKcjMeta || {},
    component: () => import("/app/pages/content/song-variants/[songVariantId].vue").then(m => m.default || m)
  },
  {
    name: "content-song-variants",
    path: "song-variants",
    meta: indexgcqaSapqfEMeta || {},
    component: () => import("/app/pages/content/song-variants/index.vue").then(m => m.default || m)
  },
  {
    name: "content-songs-songId",
    path: "songs/:songId()",
    meta: _91songId_93ufkhDCyErmMeta || {},
    component: () => import("/app/pages/content/songs/[songId].vue").then(m => m.default || m)
  },
  {
    name: "content-songs-batch-create",
    path: "songs/batch-create",
    meta: batch_45createg5fWfvIoYWMeta || {},
    component: () => import("/app/pages/content/songs/batch-create.vue").then(m => m.default || m)
  },
  {
    name: "content-songs",
    path: "songs",
    meta: indexFvg4mORv07Meta || {},
    component: () => import("/app/pages/content/songs/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  }
]