import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

const PURPLE = '#5A50AF'
const GREEN = '#17E87A'
const RED = '#D33F2C'
const BLUE = '#2374E1'
const YELLOW = '#EAC115'
const BROWN = '#97857B'
const GREY = '#757575'

const singaColorPalette = {
  primary: PURPLE,
  secondary: BROWN,
  accent: GREY,
  success: GREEN,
  info: BLUE,
  warning: YELLOW,
  error: RED
}

export default defineNuxtPlugin(({ vueApp }) => {
  const vuetify = createVuetify({
    ssr: true,
    defaults: {
      VAlert: {
        variant: 'tonal',
        border: 'start'
      },
      VAppBar: {
        density: 'comfortable'
      },
      VAutocomplete: {
        variant: 'underlined'
      },
      VList: {
        density: 'comfortable'
      },
      VPagination: {
        density: 'comfortable'
      },
      VBtn: {
        variant: 'text'
      },
      VSelect: {
        itemTitle: 'label',
        color: 'primary',
        variant: 'underlined'
      },
      VTextField: {
        color: 'primary',
        variant: 'underlined'
      },
      VFileInput: {
        variant: 'underlined'
      },
      VCheckbox: {
        color: 'primary'
      }
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    theme: {
      themes: {
        light: {
          dark: false,
          colors: singaColorPalette
        },
        dark: {
          dark: true,
          colors: singaColorPalette
        }
      },
      variations: {
        colors: Object.keys(singaColorPalette),
        lighten: 5,
        darken: 4
      }
    }
  })

  vueApp.use(vuetify)
})
