import { StatusCodes } from 'http-status-codes'
import { FetchError, type SearchParameters } from 'ofetch'
import { joinURL, withTrailingSlash } from 'ufo'

export const useAPIUtils = () => {
  const { $R } = useNuxtApp()
  const { keysToSnakeCase, keysToCamelCase, valuesToCamelCase } = useCasing()

  const $caseChangingFetch = $fetch.create({
    async onRequest ({ options }) {
      if (options.body) {
        options.body = keysToSnakeCase(options.body)
      }
      if (options.params) {
        options.params = keysToSnakeCase(options.params) as SearchParameters
      }
    },
    async onResponse ({ response }) {
      response._data = keysToCamelCase(response._data)
    }
  })

  const getTokens = (response: AuthResponse) => ({
    accessToken: response.accessToken,
    refreshToken: response.refreshToken,
    expiresIn: new Date().getTime() + (response.expiresIn * 1000)
  })

  const isUnauthorized = (err: FetchError) => {
    const statusCode = err.response?.status
    return statusCode === StatusCodes.UNAUTHORIZED
  }

  const collectErrorMessages = <T extends SingaAPIObject>(err: FetchError<T>) => $R.pipe(
    (err: FetchError<T>) => err.data?.detail ?? err.response?._data?.detail,
    // @ts-ignore: stupid sexy Ramda typings
    (errors: SingaAPIErrorObject[]) => $R.map(
      $R.modify('loc', $R.pipe(
        valuesToCamelCase,
        $R.tail
      )),
      errors
    ),
    $R.reduce(
      // @ts-ignore
      (acc, error: SingaAPIErrorObject) => $R.ifElse(
        $R.hasPath(error.loc),
        a => $R.modifyPath(error.loc, $R.append(error.msg), a),
        $R.set($R.lensPath(error.loc), [error.msg])
      // @ts-ignore
      )(acc),
      {}
    )
  )(err) as SingaAPIErrors<T>

  const getDetailUrl = (prefix: string, ...parts: any[]) => {
    const detailUrl = joinURL(prefix, ...parts)
    return withTrailingSlash(detailUrl)
  }

  const getAPIOperations = <T extends SingaAPIObject>(client: APIClient, prefix: string) => {
    const list = (params?: Params) =>
      client.get(prefix, { params }) as Promise<SingaAPIListResponse<T>>
    const create = (body: Payload) =>
      client.post(prefix, { body }) as Promise<T>

    const retrieve = (lookups: { id: string }) =>
      client.get(getDetailUrl(prefix, lookups.id)) as Promise<T>
    const update = (lookups: { id: string }, body: Payload) =>
      client.put(getDetailUrl(prefix, lookups.id), { body }) as Promise<T>
    const partialUpdate = (lookups: { id: string }, body: Payload) =>
      client.patch(getDetailUrl(prefix, lookups.id), { body }) as Promise<T>
    const destroy = (lookups: { id: string }) =>
      client.delete(getDetailUrl(prefix, lookups.id))
        // Explicitly return NULL. APIs can also return empty strings and whatever else.
        .then(() => null as any)

    const setActive = (lookups: { id: string }) =>
      client.put(getDetailUrl(prefix, lookups.id, 'set-active'), { body: {} }) as Promise<T>
    const toggleActive = (lookups: { id: string }, body: Payload) =>
      client.put(getDetailUrl(prefix, lookups.id, 'toggle-active'), { body }) as Promise<T>

    const process = (lookups: { id: string }) =>
      client.put(getDetailUrl(prefix, lookups.id, 'process')) as Promise<T>

    return {
      list,
      create,
      retrieve,
      update,
      partialUpdate,
      destroy,
      setActive,
      toggleActive,
      process
    }
  }

  type AllOperationsKeys = keyof ReturnType<typeof getAPIOperations>
  const createAPIOperations = <T extends SingaAPIObject>(
    client: APIClient,
    prefix: string,
    pick?: AllOperationsKeys[]
  ) => {
    const operations = getAPIOperations<T>(client, prefix)
    const toPick = pick ?? Object.keys(operations) as AllOperationsKeys[]

    return _pickOperations(operations, toPick)
  }

  const _pickOperations = <T extends {}, K extends keyof T>(obj: T, keys: K[]) => Object.fromEntries(
    keys
    .filter(key => key in obj)
    .map(key => [key, obj[key]])
  ) as Pick<T, K>

  return {
    $caseChangingFetch,
    getTokens,
    isUnauthorized,
    collectErrorMessages,
    getDetailUrl,
    createAPIOperations
  }
}
